import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
};

export type ActionRun = {
  __typename?: 'ActionRun';
  conclusion?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  event?: Maybe<Scalars['String']>;
  githubId?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  project?: Maybe<Project>;
  runNumber?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  totalMilliseconds?: Maybe<Scalars['Int']>;
  totalSeconds?: Maybe<Scalars['String']>;
};

export type ActionRunCreateInput = {
  conclusion?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  event?: InputMaybe<Scalars['String']>;
  githubId?: InputMaybe<Scalars['Int']>;
  project?: InputMaybe<ProjectRelateToOneForCreateInput>;
  runNumber?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['String']>;
  totalMilliseconds?: InputMaybe<Scalars['Int']>;
};

export type ActionRunManyRelationFilter = {
  every?: InputMaybe<ActionRunWhereInput>;
  none?: InputMaybe<ActionRunWhereInput>;
  some?: InputMaybe<ActionRunWhereInput>;
};

export type ActionRunOrderByInput = {
  conclusion?: InputMaybe<OrderDirection>;
  createdAt?: InputMaybe<OrderDirection>;
  event?: InputMaybe<OrderDirection>;
  githubId?: InputMaybe<OrderDirection>;
  id?: InputMaybe<OrderDirection>;
  runNumber?: InputMaybe<OrderDirection>;
  status?: InputMaybe<OrderDirection>;
  totalMilliseconds?: InputMaybe<OrderDirection>;
};

export type ActionRunRelateToManyForCreateInput = {
  connect?: InputMaybe<Array<ActionRunWhereUniqueInput>>;
  create?: InputMaybe<Array<ActionRunCreateInput>>;
};

export type ActionRunRelateToManyForUpdateInput = {
  connect?: InputMaybe<Array<ActionRunWhereUniqueInput>>;
  create?: InputMaybe<Array<ActionRunCreateInput>>;
  disconnect?: InputMaybe<Array<ActionRunWhereUniqueInput>>;
  set?: InputMaybe<Array<ActionRunWhereUniqueInput>>;
};

export type ActionRunUpdateArgs = {
  data: ActionRunUpdateInput;
  where: ActionRunWhereUniqueInput;
};

export type ActionRunUpdateInput = {
  conclusion?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  event?: InputMaybe<Scalars['String']>;
  githubId?: InputMaybe<Scalars['Int']>;
  project?: InputMaybe<ProjectRelateToOneForUpdateInput>;
  runNumber?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['String']>;
  totalMilliseconds?: InputMaybe<Scalars['Int']>;
};

export type ActionRunWhereInput = {
  AND?: InputMaybe<Array<ActionRunWhereInput>>;
  NOT?: InputMaybe<Array<ActionRunWhereInput>>;
  OR?: InputMaybe<Array<ActionRunWhereInput>>;
  conclusion?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeNullableFilter>;
  event?: InputMaybe<StringFilter>;
  githubId?: InputMaybe<IntFilter>;
  id?: InputMaybe<IdFilter>;
  project?: InputMaybe<ProjectWhereInput>;
  runNumber?: InputMaybe<IntNullableFilter>;
  status?: InputMaybe<StringFilter>;
  totalMilliseconds?: InputMaybe<IntNullableFilter>;
};

export type ActionRunWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type AuthenticatedItem = User;

export type BooleanFilter = {
  equals?: InputMaybe<Scalars['Boolean']>;
  not?: InputMaybe<BooleanFilter>;
};

export type CreateInitialUserInput = {
  displayName?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
};

export type DateTimeFilter = {
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<DateTimeFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type DateTimeNullableFilter = {
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<DateTimeNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type IdFilter = {
  equals?: InputMaybe<Scalars['ID']>;
  gt?: InputMaybe<Scalars['ID']>;
  gte?: InputMaybe<Scalars['ID']>;
  in?: InputMaybe<Array<Scalars['ID']>>;
  lt?: InputMaybe<Scalars['ID']>;
  lte?: InputMaybe<Scalars['ID']>;
  not?: InputMaybe<IdFilter>;
  notIn?: InputMaybe<Array<Scalars['ID']>>;
};

export type IntFilter = {
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<IntFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type IntNullableFilter = {
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<IntNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type KeystoneAdminMeta = {
  __typename?: 'KeystoneAdminMeta';
  enableSessionItem: Scalars['Boolean'];
  enableSignout: Scalars['Boolean'];
  list?: Maybe<KeystoneAdminUiListMeta>;
  lists: Array<KeystoneAdminUiListMeta>;
};


export type KeystoneAdminMetaListArgs = {
  key: Scalars['String'];
};

export type KeystoneAdminUiFieldMeta = {
  __typename?: 'KeystoneAdminUIFieldMeta';
  createView: KeystoneAdminUiFieldMetaCreateView;
  customViewsIndex?: Maybe<Scalars['Int']>;
  fieldMeta?: Maybe<Scalars['JSON']>;
  isFilterable: Scalars['Boolean'];
  isOrderable: Scalars['Boolean'];
  itemView?: Maybe<KeystoneAdminUiFieldMetaItemView>;
  label: Scalars['String'];
  listView: KeystoneAdminUiFieldMetaListView;
  path: Scalars['String'];
  search?: Maybe<QueryMode>;
  viewsIndex: Scalars['Int'];
};


export type KeystoneAdminUiFieldMetaItemViewArgs = {
  id?: InputMaybe<Scalars['ID']>;
};

export type KeystoneAdminUiFieldMetaCreateView = {
  __typename?: 'KeystoneAdminUIFieldMetaCreateView';
  fieldMode: KeystoneAdminUiFieldMetaCreateViewFieldMode;
};

export enum KeystoneAdminUiFieldMetaCreateViewFieldMode {
  Edit = 'edit',
  Hidden = 'hidden'
}

export type KeystoneAdminUiFieldMetaItemView = {
  __typename?: 'KeystoneAdminUIFieldMetaItemView';
  fieldMode?: Maybe<KeystoneAdminUiFieldMetaItemViewFieldMode>;
};

export enum KeystoneAdminUiFieldMetaItemViewFieldMode {
  Edit = 'edit',
  Hidden = 'hidden',
  Read = 'read'
}

export type KeystoneAdminUiFieldMetaListView = {
  __typename?: 'KeystoneAdminUIFieldMetaListView';
  fieldMode: KeystoneAdminUiFieldMetaListViewFieldMode;
};

export enum KeystoneAdminUiFieldMetaListViewFieldMode {
  Hidden = 'hidden',
  Read = 'read'
}

export type KeystoneAdminUiListMeta = {
  __typename?: 'KeystoneAdminUIListMeta';
  description?: Maybe<Scalars['String']>;
  fields: Array<KeystoneAdminUiFieldMeta>;
  hideCreate: Scalars['Boolean'];
  hideDelete: Scalars['Boolean'];
  initialColumns: Array<Scalars['String']>;
  initialSort?: Maybe<KeystoneAdminUiSort>;
  isHidden: Scalars['Boolean'];
  itemQueryName: Scalars['String'];
  key: Scalars['String'];
  label: Scalars['String'];
  labelField: Scalars['String'];
  listQueryName: Scalars['String'];
  pageSize: Scalars['Int'];
  path: Scalars['String'];
  plural: Scalars['String'];
  singular: Scalars['String'];
};

export type KeystoneAdminUiSort = {
  __typename?: 'KeystoneAdminUISort';
  direction: KeystoneAdminUiSortDirection;
  field: Scalars['String'];
};

export enum KeystoneAdminUiSortDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type KeystoneMeta = {
  __typename?: 'KeystoneMeta';
  adminMeta: KeystoneAdminMeta;
};

export type Mutation = {
  __typename?: 'Mutation';
  authenticateUserWithPassword?: Maybe<UserAuthenticationWithPasswordResult>;
  createActionRun?: Maybe<ActionRun>;
  createActionRuns?: Maybe<Array<Maybe<ActionRun>>>;
  createInitialUser: UserAuthenticationWithPasswordSuccess;
  createNotification?: Maybe<Notification>;
  createNotifications?: Maybe<Array<Maybe<Notification>>>;
  createPage?: Maybe<Page>;
  createPages?: Maybe<Array<Maybe<Page>>>;
  createProject?: Maybe<Project>;
  createProjects?: Maybe<Array<Maybe<Project>>>;
  createTicket?: Maybe<Ticket>;
  createTicketResponse?: Maybe<TicketResponse>;
  createTicketResponses?: Maybe<Array<Maybe<TicketResponse>>>;
  createTickets?: Maybe<Array<Maybe<Ticket>>>;
  createUser?: Maybe<User>;
  createUsers?: Maybe<Array<Maybe<User>>>;
  deleteActionRun?: Maybe<ActionRun>;
  deleteActionRuns?: Maybe<Array<Maybe<ActionRun>>>;
  deleteNotification?: Maybe<Notification>;
  deleteNotifications?: Maybe<Array<Maybe<Notification>>>;
  deletePage?: Maybe<Page>;
  deletePages?: Maybe<Array<Maybe<Page>>>;
  deleteProject?: Maybe<Project>;
  deleteProjects?: Maybe<Array<Maybe<Project>>>;
  deleteTicket?: Maybe<Ticket>;
  deleteTicketResponse?: Maybe<TicketResponse>;
  deleteTicketResponses?: Maybe<Array<Maybe<TicketResponse>>>;
  deleteTickets?: Maybe<Array<Maybe<Ticket>>>;
  deleteUser?: Maybe<User>;
  deleteUsers?: Maybe<Array<Maybe<User>>>;
  endSession: Scalars['Boolean'];
  redeemUserPasswordResetToken?: Maybe<RedeemUserPasswordResetTokenResult>;
  sendUserPasswordResetLink: Scalars['Boolean'];
  syncGithubActions?: Maybe<Scalars['Boolean']>;
  test?: Maybe<Scalars['Boolean']>;
  updateActionRun?: Maybe<ActionRun>;
  updateActionRuns?: Maybe<Array<Maybe<ActionRun>>>;
  updateNotification?: Maybe<Notification>;
  updateNotifications?: Maybe<Array<Maybe<Notification>>>;
  updatePage?: Maybe<Page>;
  updatePages?: Maybe<Array<Maybe<Page>>>;
  updateProject?: Maybe<Project>;
  updateProjects?: Maybe<Array<Maybe<Project>>>;
  updateTicket?: Maybe<Ticket>;
  updateTicketResponse?: Maybe<TicketResponse>;
  updateTicketResponses?: Maybe<Array<Maybe<TicketResponse>>>;
  updateTickets?: Maybe<Array<Maybe<Ticket>>>;
  updateUser?: Maybe<User>;
  updateUsers?: Maybe<Array<Maybe<User>>>;
};


export type MutationAuthenticateUserWithPasswordArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};


export type MutationCreateActionRunArgs = {
  data: ActionRunCreateInput;
};


export type MutationCreateActionRunsArgs = {
  data: Array<ActionRunCreateInput>;
};


export type MutationCreateInitialUserArgs = {
  data: CreateInitialUserInput;
};


export type MutationCreateNotificationArgs = {
  data: NotificationCreateInput;
};


export type MutationCreateNotificationsArgs = {
  data: Array<NotificationCreateInput>;
};


export type MutationCreatePageArgs = {
  data: PageCreateInput;
};


export type MutationCreatePagesArgs = {
  data: Array<PageCreateInput>;
};


export type MutationCreateProjectArgs = {
  data: ProjectCreateInput;
};


export type MutationCreateProjectsArgs = {
  data: Array<ProjectCreateInput>;
};


export type MutationCreateTicketArgs = {
  data: TicketCreateInput;
};


export type MutationCreateTicketResponseArgs = {
  data: TicketResponseCreateInput;
};


export type MutationCreateTicketResponsesArgs = {
  data: Array<TicketResponseCreateInput>;
};


export type MutationCreateTicketsArgs = {
  data: Array<TicketCreateInput>;
};


export type MutationCreateUserArgs = {
  data: UserCreateInput;
};


export type MutationCreateUsersArgs = {
  data: Array<UserCreateInput>;
};


export type MutationDeleteActionRunArgs = {
  where: ActionRunWhereUniqueInput;
};


export type MutationDeleteActionRunsArgs = {
  where: Array<ActionRunWhereUniqueInput>;
};


export type MutationDeleteNotificationArgs = {
  where: NotificationWhereUniqueInput;
};


export type MutationDeleteNotificationsArgs = {
  where: Array<NotificationWhereUniqueInput>;
};


export type MutationDeletePageArgs = {
  where: PageWhereUniqueInput;
};


export type MutationDeletePagesArgs = {
  where: Array<PageWhereUniqueInput>;
};


export type MutationDeleteProjectArgs = {
  where: ProjectWhereUniqueInput;
};


export type MutationDeleteProjectsArgs = {
  where: Array<ProjectWhereUniqueInput>;
};


export type MutationDeleteTicketArgs = {
  where: TicketWhereUniqueInput;
};


export type MutationDeleteTicketResponseArgs = {
  where: TicketResponseWhereUniqueInput;
};


export type MutationDeleteTicketResponsesArgs = {
  where: Array<TicketResponseWhereUniqueInput>;
};


export type MutationDeleteTicketsArgs = {
  where: Array<TicketWhereUniqueInput>;
};


export type MutationDeleteUserArgs = {
  where: UserWhereUniqueInput;
};


export type MutationDeleteUsersArgs = {
  where: Array<UserWhereUniqueInput>;
};


export type MutationRedeemUserPasswordResetTokenArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
  token: Scalars['String'];
};


export type MutationSendUserPasswordResetLinkArgs = {
  email: Scalars['String'];
};


export type MutationUpdateActionRunArgs = {
  data: ActionRunUpdateInput;
  where: ActionRunWhereUniqueInput;
};


export type MutationUpdateActionRunsArgs = {
  data: Array<ActionRunUpdateArgs>;
};


export type MutationUpdateNotificationArgs = {
  data: NotificationUpdateInput;
  where: NotificationWhereUniqueInput;
};


export type MutationUpdateNotificationsArgs = {
  data: Array<NotificationUpdateArgs>;
};


export type MutationUpdatePageArgs = {
  data: PageUpdateInput;
  where: PageWhereUniqueInput;
};


export type MutationUpdatePagesArgs = {
  data: Array<PageUpdateArgs>;
};


export type MutationUpdateProjectArgs = {
  data: ProjectUpdateInput;
  where: ProjectWhereUniqueInput;
};


export type MutationUpdateProjectsArgs = {
  data: Array<ProjectUpdateArgs>;
};


export type MutationUpdateTicketArgs = {
  data: TicketUpdateInput;
  where: TicketWhereUniqueInput;
};


export type MutationUpdateTicketResponseArgs = {
  data: TicketResponseUpdateInput;
  where: TicketResponseWhereUniqueInput;
};


export type MutationUpdateTicketResponsesArgs = {
  data: Array<TicketResponseUpdateArgs>;
};


export type MutationUpdateTicketsArgs = {
  data: Array<TicketUpdateArgs>;
};


export type MutationUpdateUserArgs = {
  data: UserUpdateInput;
  where: UserWhereUniqueInput;
};


export type MutationUpdateUsersArgs = {
  data: Array<UserUpdateArgs>;
};

export type NestedStringFilter = {
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  not?: InputMaybe<NestedStringFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type Notification = {
  __typename?: 'Notification';
  closeable?: Maybe<Scalars['Boolean']>;
  display?: Maybe<NotificationDisplayType>;
  end?: Maybe<Scalars['DateTime']>;
  hideTitle?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  message?: Maybe<Scalars['String']>;
  severity?: Maybe<NotificationSeverityType>;
  start?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
};

export type NotificationCreateInput = {
  closeable?: InputMaybe<Scalars['Boolean']>;
  display?: InputMaybe<NotificationDisplayType>;
  end?: InputMaybe<Scalars['DateTime']>;
  hideTitle?: InputMaybe<Scalars['Boolean']>;
  message?: InputMaybe<Scalars['String']>;
  severity?: InputMaybe<NotificationSeverityType>;
  start?: InputMaybe<Scalars['DateTime']>;
  title?: InputMaybe<Scalars['String']>;
};

export enum NotificationDisplayType {
  Hidden = 'hidden',
  Show = 'show',
  Timed = 'timed'
}

export type NotificationDisplayTypeNullableFilter = {
  equals?: InputMaybe<NotificationDisplayType>;
  in?: InputMaybe<Array<NotificationDisplayType>>;
  not?: InputMaybe<NotificationDisplayTypeNullableFilter>;
  notIn?: InputMaybe<Array<NotificationDisplayType>>;
};

export type NotificationOrderByInput = {
  closeable?: InputMaybe<OrderDirection>;
  display?: InputMaybe<OrderDirection>;
  end?: InputMaybe<OrderDirection>;
  hideTitle?: InputMaybe<OrderDirection>;
  id?: InputMaybe<OrderDirection>;
  message?: InputMaybe<OrderDirection>;
  severity?: InputMaybe<OrderDirection>;
  start?: InputMaybe<OrderDirection>;
  title?: InputMaybe<OrderDirection>;
};

export enum NotificationSeverityType {
  Error = 'error',
  Info = 'info',
  Success = 'success',
  Warning = 'warning'
}

export type NotificationSeverityTypeNullableFilter = {
  equals?: InputMaybe<NotificationSeverityType>;
  in?: InputMaybe<Array<NotificationSeverityType>>;
  not?: InputMaybe<NotificationSeverityTypeNullableFilter>;
  notIn?: InputMaybe<Array<NotificationSeverityType>>;
};

export type NotificationUpdateArgs = {
  data: NotificationUpdateInput;
  where: NotificationWhereUniqueInput;
};

export type NotificationUpdateInput = {
  closeable?: InputMaybe<Scalars['Boolean']>;
  display?: InputMaybe<NotificationDisplayType>;
  end?: InputMaybe<Scalars['DateTime']>;
  hideTitle?: InputMaybe<Scalars['Boolean']>;
  message?: InputMaybe<Scalars['String']>;
  severity?: InputMaybe<NotificationSeverityType>;
  start?: InputMaybe<Scalars['DateTime']>;
  title?: InputMaybe<Scalars['String']>;
};

export type NotificationWhereInput = {
  AND?: InputMaybe<Array<NotificationWhereInput>>;
  NOT?: InputMaybe<Array<NotificationWhereInput>>;
  OR?: InputMaybe<Array<NotificationWhereInput>>;
  closeable?: InputMaybe<BooleanFilter>;
  display?: InputMaybe<NotificationDisplayTypeNullableFilter>;
  end?: InputMaybe<DateTimeNullableFilter>;
  hideTitle?: InputMaybe<BooleanFilter>;
  id?: InputMaybe<IdFilter>;
  message?: InputMaybe<StringFilter>;
  severity?: InputMaybe<NotificationSeverityTypeNullableFilter>;
  start?: InputMaybe<DateTimeNullableFilter>;
  title?: InputMaybe<StringFilter>;
};

export type NotificationWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export enum OrderDirection {
  Asc = 'asc',
  Desc = 'desc'
}

export type Page = {
  __typename?: 'Page';
  content?: Maybe<Page_Content_Document>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  id: Scalars['ID'];
  slug?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<User>;
};

export type PageCreateInput = {
  content?: InputMaybe<Scalars['JSON']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdBy?: InputMaybe<UserRelateToOneForCreateInput>;
  slug?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedBy?: InputMaybe<UserRelateToOneForCreateInput>;
};

export type PageManyRelationFilter = {
  every?: InputMaybe<PageWhereInput>;
  none?: InputMaybe<PageWhereInput>;
  some?: InputMaybe<PageWhereInput>;
};

export type PageOrderByInput = {
  createdAt?: InputMaybe<OrderDirection>;
  id?: InputMaybe<OrderDirection>;
  slug?: InputMaybe<OrderDirection>;
  title?: InputMaybe<OrderDirection>;
  updatedAt?: InputMaybe<OrderDirection>;
};

export type PageRelateToManyForCreateInput = {
  connect?: InputMaybe<Array<PageWhereUniqueInput>>;
  create?: InputMaybe<Array<PageCreateInput>>;
};

export type PageRelateToManyForUpdateInput = {
  connect?: InputMaybe<Array<PageWhereUniqueInput>>;
  create?: InputMaybe<Array<PageCreateInput>>;
  disconnect?: InputMaybe<Array<PageWhereUniqueInput>>;
  set?: InputMaybe<Array<PageWhereUniqueInput>>;
};

export type PageUpdateArgs = {
  data: PageUpdateInput;
  where: PageWhereUniqueInput;
};

export type PageUpdateInput = {
  content?: InputMaybe<Scalars['JSON']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdBy?: InputMaybe<UserRelateToOneForUpdateInput>;
  slug?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  updatedBy?: InputMaybe<UserRelateToOneForUpdateInput>;
};

export type PageWhereInput = {
  AND?: InputMaybe<Array<PageWhereInput>>;
  NOT?: InputMaybe<Array<PageWhereInput>>;
  OR?: InputMaybe<Array<PageWhereInput>>;
  createdAt?: InputMaybe<DateTimeNullableFilter>;
  createdBy?: InputMaybe<UserWhereInput>;
  id?: InputMaybe<IdFilter>;
  slug?: InputMaybe<StringFilter>;
  title?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeNullableFilter>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export type PageWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
  slug?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type Page_Content_Document = {
  __typename?: 'Page_content_Document';
  document: Scalars['JSON'];
};


export type Page_Content_DocumentDocumentArgs = {
  hydrateRelationships?: Scalars['Boolean'];
};

export type PasswordFilter = {
  isSet: Scalars['Boolean'];
};

export enum PasswordResetRedemptionErrorCode {
  Failure = 'FAILURE',
  TokenExpired = 'TOKEN_EXPIRED',
  TokenRedeemed = 'TOKEN_REDEEMED'
}

export type PasswordState = {
  __typename?: 'PasswordState';
  isSet: Scalars['Boolean'];
};

export type Project = {
  __typename?: 'Project';
  buildMinutes?: Maybe<Scalars['Int']>;
  dropletIp?: Maybe<Scalars['String']>;
  dropletPrice?: Maybe<Scalars['Int']>;
  endHosting?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  numberOfDomains?: Maybe<Scalars['Int']>;
  repo?: Maybe<Scalars['String']>;
  runs?: Maybe<Array<ActionRun>>;
  runsCount?: Maybe<Scalars['Int']>;
  startHosting?: Maybe<Scalars['DateTime']>;
  statsByMonth?: Maybe<Array<Maybe<StatsByMonthType>>>;
  user?: Maybe<User>;
  website?: Maybe<Scalars['String']>;
};


export type ProjectRunsArgs = {
  orderBy?: Array<ActionRunOrderByInput>;
  skip?: Scalars['Int'];
  take?: InputMaybe<Scalars['Int']>;
  where?: ActionRunWhereInput;
};


export type ProjectRunsCountArgs = {
  where?: ActionRunWhereInput;
};


export type ProjectStatsByMonthArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<SortStatsByMonthEnum>;
};

export type ProjectCreateInput = {
  buildMinutes?: InputMaybe<Scalars['Int']>;
  dropletIp?: InputMaybe<Scalars['String']>;
  dropletPrice?: InputMaybe<Scalars['Int']>;
  endHosting?: InputMaybe<Scalars['DateTime']>;
  name?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  numberOfDomains?: InputMaybe<Scalars['Int']>;
  repo?: InputMaybe<Scalars['String']>;
  runs?: InputMaybe<ActionRunRelateToManyForCreateInput>;
  startHosting?: InputMaybe<Scalars['DateTime']>;
  user?: InputMaybe<UserRelateToOneForCreateInput>;
  website?: InputMaybe<Scalars['String']>;
};

export type ProjectManyRelationFilter = {
  every?: InputMaybe<ProjectWhereInput>;
  none?: InputMaybe<ProjectWhereInput>;
  some?: InputMaybe<ProjectWhereInput>;
};

export type ProjectOrderByInput = {
  buildMinutes?: InputMaybe<OrderDirection>;
  dropletIp?: InputMaybe<OrderDirection>;
  dropletPrice?: InputMaybe<OrderDirection>;
  endHosting?: InputMaybe<OrderDirection>;
  id?: InputMaybe<OrderDirection>;
  name?: InputMaybe<OrderDirection>;
  notes?: InputMaybe<OrderDirection>;
  numberOfDomains?: InputMaybe<OrderDirection>;
  repo?: InputMaybe<OrderDirection>;
  startHosting?: InputMaybe<OrderDirection>;
  website?: InputMaybe<OrderDirection>;
};

export type ProjectRelateToManyForCreateInput = {
  connect?: InputMaybe<Array<ProjectWhereUniqueInput>>;
  create?: InputMaybe<Array<ProjectCreateInput>>;
};

export type ProjectRelateToManyForUpdateInput = {
  connect?: InputMaybe<Array<ProjectWhereUniqueInput>>;
  create?: InputMaybe<Array<ProjectCreateInput>>;
  disconnect?: InputMaybe<Array<ProjectWhereUniqueInput>>;
  set?: InputMaybe<Array<ProjectWhereUniqueInput>>;
};

export type ProjectRelateToOneForCreateInput = {
  connect?: InputMaybe<ProjectWhereUniqueInput>;
  create?: InputMaybe<ProjectCreateInput>;
};

export type ProjectRelateToOneForUpdateInput = {
  connect?: InputMaybe<ProjectWhereUniqueInput>;
  create?: InputMaybe<ProjectCreateInput>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
};

export type ProjectUpdateArgs = {
  data: ProjectUpdateInput;
  where: ProjectWhereUniqueInput;
};

export type ProjectUpdateInput = {
  buildMinutes?: InputMaybe<Scalars['Int']>;
  dropletIp?: InputMaybe<Scalars['String']>;
  dropletPrice?: InputMaybe<Scalars['Int']>;
  endHosting?: InputMaybe<Scalars['DateTime']>;
  name?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  numberOfDomains?: InputMaybe<Scalars['Int']>;
  repo?: InputMaybe<Scalars['String']>;
  runs?: InputMaybe<ActionRunRelateToManyForUpdateInput>;
  startHosting?: InputMaybe<Scalars['DateTime']>;
  user?: InputMaybe<UserRelateToOneForUpdateInput>;
  website?: InputMaybe<Scalars['String']>;
};

export type ProjectWhereInput = {
  AND?: InputMaybe<Array<ProjectWhereInput>>;
  NOT?: InputMaybe<Array<ProjectWhereInput>>;
  OR?: InputMaybe<Array<ProjectWhereInput>>;
  buildMinutes?: InputMaybe<IntNullableFilter>;
  dropletIp?: InputMaybe<StringFilter>;
  dropletPrice?: InputMaybe<IntNullableFilter>;
  endHosting?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<IdFilter>;
  name?: InputMaybe<StringFilter>;
  notes?: InputMaybe<StringFilter>;
  numberOfDomains?: InputMaybe<IntNullableFilter>;
  repo?: InputMaybe<StringFilter>;
  runs?: InputMaybe<ActionRunManyRelationFilter>;
  startHosting?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserWhereInput>;
  website?: InputMaybe<StringFilter>;
};

export type ProjectWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  actionRun?: Maybe<ActionRun>;
  actionRuns?: Maybe<Array<ActionRun>>;
  actionRunsCount?: Maybe<Scalars['Int']>;
  authenticatedItem?: Maybe<AuthenticatedItem>;
  keystone: KeystoneMeta;
  notification?: Maybe<Notification>;
  notifications?: Maybe<Array<Notification>>;
  notificationsCount?: Maybe<Scalars['Int']>;
  page?: Maybe<Page>;
  pages?: Maybe<Array<Page>>;
  pagesCount?: Maybe<Scalars['Int']>;
  project?: Maybe<Project>;
  projects?: Maybe<Array<Project>>;
  projectsCount?: Maybe<Scalars['Int']>;
  repoList?: Maybe<Array<Maybe<Scalars['String']>>>;
  ticket?: Maybe<Ticket>;
  ticketResponse?: Maybe<TicketResponse>;
  ticketResponses?: Maybe<Array<TicketResponse>>;
  ticketResponsesCount?: Maybe<Scalars['Int']>;
  tickets?: Maybe<Array<Ticket>>;
  ticketsCount?: Maybe<Scalars['Int']>;
  user?: Maybe<User>;
  users?: Maybe<Array<User>>;
  usersCount?: Maybe<Scalars['Int']>;
  validateUserPasswordResetToken?: Maybe<ValidateUserPasswordResetTokenResult>;
};


export type QueryActionRunArgs = {
  where: ActionRunWhereUniqueInput;
};


export type QueryActionRunsArgs = {
  orderBy?: Array<ActionRunOrderByInput>;
  skip?: Scalars['Int'];
  take?: InputMaybe<Scalars['Int']>;
  where?: ActionRunWhereInput;
};


export type QueryActionRunsCountArgs = {
  where?: ActionRunWhereInput;
};


export type QueryNotificationArgs = {
  where: NotificationWhereUniqueInput;
};


export type QueryNotificationsArgs = {
  orderBy?: Array<NotificationOrderByInput>;
  skip?: Scalars['Int'];
  take?: InputMaybe<Scalars['Int']>;
  where?: NotificationWhereInput;
};


export type QueryNotificationsCountArgs = {
  where?: NotificationWhereInput;
};


export type QueryPageArgs = {
  where: PageWhereUniqueInput;
};


export type QueryPagesArgs = {
  orderBy?: Array<PageOrderByInput>;
  skip?: Scalars['Int'];
  take?: InputMaybe<Scalars['Int']>;
  where?: PageWhereInput;
};


export type QueryPagesCountArgs = {
  where?: PageWhereInput;
};


export type QueryProjectArgs = {
  where: ProjectWhereUniqueInput;
};


export type QueryProjectsArgs = {
  orderBy?: Array<ProjectOrderByInput>;
  skip?: Scalars['Int'];
  take?: InputMaybe<Scalars['Int']>;
  where?: ProjectWhereInput;
};


export type QueryProjectsCountArgs = {
  where?: ProjectWhereInput;
};


export type QueryTicketArgs = {
  where: TicketWhereUniqueInput;
};


export type QueryTicketResponseArgs = {
  where: TicketResponseWhereUniqueInput;
};


export type QueryTicketResponsesArgs = {
  orderBy?: Array<TicketResponseOrderByInput>;
  skip?: Scalars['Int'];
  take?: InputMaybe<Scalars['Int']>;
  where?: TicketResponseWhereInput;
};


export type QueryTicketResponsesCountArgs = {
  where?: TicketResponseWhereInput;
};


export type QueryTicketsArgs = {
  orderBy?: Array<TicketOrderByInput>;
  skip?: Scalars['Int'];
  take?: InputMaybe<Scalars['Int']>;
  where?: TicketWhereInput;
};


export type QueryTicketsCountArgs = {
  where?: TicketWhereInput;
};


export type QueryUserArgs = {
  where: UserWhereUniqueInput;
};


export type QueryUsersArgs = {
  orderBy?: Array<UserOrderByInput>;
  skip?: Scalars['Int'];
  take?: InputMaybe<Scalars['Int']>;
  where?: UserWhereInput;
};


export type QueryUsersCountArgs = {
  where?: UserWhereInput;
};


export type QueryValidateUserPasswordResetTokenArgs = {
  email: Scalars['String'];
  token: Scalars['String'];
};

export enum QueryMode {
  Default = 'default',
  Insensitive = 'insensitive'
}

export type RedeemUserPasswordResetTokenResult = {
  __typename?: 'RedeemUserPasswordResetTokenResult';
  code: PasswordResetRedemptionErrorCode;
  message: Scalars['String'];
};

export enum SortStatsByMonthEnum {
  Asc = 'asc',
  Desc = 'desc'
}

export type StatsByMonthType = {
  __typename?: 'StatsByMonthType';
  average?: Maybe<Scalars['Int']>;
  avgMinutes?: Maybe<Scalars['String']>;
  milliseconds?: Maybe<Scalars['Int']>;
  minutes?: Maybe<Scalars['String']>;
  minutesLeftThisMonth?: Maybe<Scalars['String']>;
  month?: Maybe<Scalars['Int']>;
  runs?: Maybe<Scalars['Int']>;
  year?: Maybe<Scalars['Int']>;
};

export type StringFilter = {
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedStringFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type Ticket = {
  __typename?: 'Ticket';
  createdAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  label?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  responses?: Maybe<Array<TicketResponse>>;
  responsesCount?: Maybe<Scalars['Int']>;
  status?: Maybe<TicketStatusType>;
  subject?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
};


export type TicketResponsesArgs = {
  orderBy?: Array<TicketResponseOrderByInput>;
  skip?: Scalars['Int'];
  take?: InputMaybe<Scalars['Int']>;
  where?: TicketResponseWhereInput;
};


export type TicketResponsesCountArgs = {
  where?: TicketResponseWhereInput;
};

export type TicketCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  message?: InputMaybe<Scalars['String']>;
  responses?: InputMaybe<TicketResponseRelateToManyForCreateInput>;
  status?: InputMaybe<TicketStatusType>;
  subject?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<UserRelateToOneForCreateInput>;
};

export type TicketManyRelationFilter = {
  every?: InputMaybe<TicketWhereInput>;
  none?: InputMaybe<TicketWhereInput>;
  some?: InputMaybe<TicketWhereInput>;
};

export type TicketOrderByInput = {
  createdAt?: InputMaybe<OrderDirection>;
  id?: InputMaybe<OrderDirection>;
  message?: InputMaybe<OrderDirection>;
  status?: InputMaybe<OrderDirection>;
  subject?: InputMaybe<OrderDirection>;
};

export type TicketRelateToManyForCreateInput = {
  connect?: InputMaybe<Array<TicketWhereUniqueInput>>;
  create?: InputMaybe<Array<TicketCreateInput>>;
};

export type TicketRelateToManyForUpdateInput = {
  connect?: InputMaybe<Array<TicketWhereUniqueInput>>;
  create?: InputMaybe<Array<TicketCreateInput>>;
  disconnect?: InputMaybe<Array<TicketWhereUniqueInput>>;
  set?: InputMaybe<Array<TicketWhereUniqueInput>>;
};

export type TicketRelateToOneForCreateInput = {
  connect?: InputMaybe<TicketWhereUniqueInput>;
  create?: InputMaybe<TicketCreateInput>;
};

export type TicketRelateToOneForUpdateInput = {
  connect?: InputMaybe<TicketWhereUniqueInput>;
  create?: InputMaybe<TicketCreateInput>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
};

export type TicketResponse = {
  __typename?: 'TicketResponse';
  createdAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  label?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  parent?: Maybe<Ticket>;
  user?: Maybe<User>;
};

export type TicketResponseCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  message?: InputMaybe<Scalars['String']>;
  parent?: InputMaybe<TicketRelateToOneForCreateInput>;
  user?: InputMaybe<UserRelateToOneForCreateInput>;
};

export type TicketResponseManyRelationFilter = {
  every?: InputMaybe<TicketResponseWhereInput>;
  none?: InputMaybe<TicketResponseWhereInput>;
  some?: InputMaybe<TicketResponseWhereInput>;
};

export type TicketResponseOrderByInput = {
  createdAt?: InputMaybe<OrderDirection>;
  id?: InputMaybe<OrderDirection>;
  message?: InputMaybe<OrderDirection>;
};

export type TicketResponseRelateToManyForCreateInput = {
  connect?: InputMaybe<Array<TicketResponseWhereUniqueInput>>;
  create?: InputMaybe<Array<TicketResponseCreateInput>>;
};

export type TicketResponseRelateToManyForUpdateInput = {
  connect?: InputMaybe<Array<TicketResponseWhereUniqueInput>>;
  create?: InputMaybe<Array<TicketResponseCreateInput>>;
  disconnect?: InputMaybe<Array<TicketResponseWhereUniqueInput>>;
  set?: InputMaybe<Array<TicketResponseWhereUniqueInput>>;
};

export type TicketResponseUpdateArgs = {
  data: TicketResponseUpdateInput;
  where: TicketResponseWhereUniqueInput;
};

export type TicketResponseUpdateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  message?: InputMaybe<Scalars['String']>;
  parent?: InputMaybe<TicketRelateToOneForUpdateInput>;
  user?: InputMaybe<UserRelateToOneForUpdateInput>;
};

export type TicketResponseWhereInput = {
  AND?: InputMaybe<Array<TicketResponseWhereInput>>;
  NOT?: InputMaybe<Array<TicketResponseWhereInput>>;
  OR?: InputMaybe<Array<TicketResponseWhereInput>>;
  createdAt?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<IdFilter>;
  message?: InputMaybe<StringFilter>;
  parent?: InputMaybe<TicketWhereInput>;
  user?: InputMaybe<UserWhereInput>;
};

export type TicketResponseWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export enum TicketStatusType {
  Completed = 'COMPLETED',
  New = 'NEW',
  Pending = 'PENDING'
}

export type TicketStatusTypeNullableFilter = {
  equals?: InputMaybe<TicketStatusType>;
  in?: InputMaybe<Array<TicketStatusType>>;
  not?: InputMaybe<TicketStatusTypeNullableFilter>;
  notIn?: InputMaybe<Array<TicketStatusType>>;
};

export type TicketUpdateArgs = {
  data: TicketUpdateInput;
  where: TicketWhereUniqueInput;
};

export type TicketUpdateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  message?: InputMaybe<Scalars['String']>;
  responses?: InputMaybe<TicketResponseRelateToManyForUpdateInput>;
  status?: InputMaybe<TicketStatusType>;
  subject?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<UserRelateToOneForUpdateInput>;
};

export type TicketWhereInput = {
  AND?: InputMaybe<Array<TicketWhereInput>>;
  NOT?: InputMaybe<Array<TicketWhereInput>>;
  OR?: InputMaybe<Array<TicketWhereInput>>;
  createdAt?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<IdFilter>;
  message?: InputMaybe<StringFilter>;
  responses?: InputMaybe<TicketResponseManyRelationFilter>;
  status?: InputMaybe<TicketStatusTypeNullableFilter>;
  subject?: InputMaybe<StringFilter>;
  user?: InputMaybe<UserWhereInput>;
};

export type TicketWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type User = {
  __typename?: 'User';
  displayName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isAdmin?: Maybe<Scalars['Boolean']>;
  pages?: Maybe<Array<Page>>;
  pagesCount?: Maybe<Scalars['Int']>;
  password?: Maybe<PasswordState>;
  passwordResetIssuedAt?: Maybe<Scalars['DateTime']>;
  passwordResetRedeemedAt?: Maybe<Scalars['DateTime']>;
  passwordResetToken?: Maybe<PasswordState>;
  projects?: Maybe<Array<Project>>;
  projectsCount?: Maybe<Scalars['Int']>;
  tickets?: Maybe<Array<Ticket>>;
  ticketsCount?: Maybe<Scalars['Int']>;
};


export type UserPagesArgs = {
  orderBy?: Array<PageOrderByInput>;
  skip?: Scalars['Int'];
  take?: InputMaybe<Scalars['Int']>;
  where?: PageWhereInput;
};


export type UserPagesCountArgs = {
  where?: PageWhereInput;
};


export type UserProjectsArgs = {
  orderBy?: Array<ProjectOrderByInput>;
  skip?: Scalars['Int'];
  take?: InputMaybe<Scalars['Int']>;
  where?: ProjectWhereInput;
};


export type UserProjectsCountArgs = {
  where?: ProjectWhereInput;
};


export type UserTicketsArgs = {
  orderBy?: Array<TicketOrderByInput>;
  skip?: Scalars['Int'];
  take?: InputMaybe<Scalars['Int']>;
  where?: TicketWhereInput;
};


export type UserTicketsCountArgs = {
  where?: TicketWhereInput;
};

export type UserAuthenticationWithPasswordFailure = {
  __typename?: 'UserAuthenticationWithPasswordFailure';
  message: Scalars['String'];
};

export type UserAuthenticationWithPasswordResult = UserAuthenticationWithPasswordFailure | UserAuthenticationWithPasswordSuccess;

export type UserAuthenticationWithPasswordSuccess = {
  __typename?: 'UserAuthenticationWithPasswordSuccess';
  item: User;
  sessionToken: Scalars['String'];
};

export type UserCreateInput = {
  displayName?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  isAdmin?: InputMaybe<Scalars['Boolean']>;
  pages?: InputMaybe<PageRelateToManyForCreateInput>;
  password?: InputMaybe<Scalars['String']>;
  passwordResetIssuedAt?: InputMaybe<Scalars['DateTime']>;
  passwordResetRedeemedAt?: InputMaybe<Scalars['DateTime']>;
  passwordResetToken?: InputMaybe<Scalars['String']>;
  projects?: InputMaybe<ProjectRelateToManyForCreateInput>;
  tickets?: InputMaybe<TicketRelateToManyForCreateInput>;
};

export type UserOrderByInput = {
  displayName?: InputMaybe<OrderDirection>;
  email?: InputMaybe<OrderDirection>;
  id?: InputMaybe<OrderDirection>;
  isAdmin?: InputMaybe<OrderDirection>;
  passwordResetIssuedAt?: InputMaybe<OrderDirection>;
  passwordResetRedeemedAt?: InputMaybe<OrderDirection>;
};

export type UserRelateToOneForCreateInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  create?: InputMaybe<UserCreateInput>;
};

export type UserRelateToOneForUpdateInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  create?: InputMaybe<UserCreateInput>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
};

export type UserUpdateArgs = {
  data: UserUpdateInput;
  where: UserWhereUniqueInput;
};

export type UserUpdateInput = {
  displayName?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  isAdmin?: InputMaybe<Scalars['Boolean']>;
  pages?: InputMaybe<PageRelateToManyForUpdateInput>;
  password?: InputMaybe<Scalars['String']>;
  passwordResetIssuedAt?: InputMaybe<Scalars['DateTime']>;
  passwordResetRedeemedAt?: InputMaybe<Scalars['DateTime']>;
  passwordResetToken?: InputMaybe<Scalars['String']>;
  projects?: InputMaybe<ProjectRelateToManyForUpdateInput>;
  tickets?: InputMaybe<TicketRelateToManyForUpdateInput>;
};

export type UserWhereInput = {
  AND?: InputMaybe<Array<UserWhereInput>>;
  NOT?: InputMaybe<Array<UserWhereInput>>;
  OR?: InputMaybe<Array<UserWhereInput>>;
  displayName?: InputMaybe<StringFilter>;
  email?: InputMaybe<StringFilter>;
  id?: InputMaybe<IdFilter>;
  isAdmin?: InputMaybe<BooleanFilter>;
  pages?: InputMaybe<PageManyRelationFilter>;
  password?: InputMaybe<PasswordFilter>;
  passwordResetIssuedAt?: InputMaybe<DateTimeNullableFilter>;
  passwordResetRedeemedAt?: InputMaybe<DateTimeNullableFilter>;
  passwordResetToken?: InputMaybe<PasswordFilter>;
  projects?: InputMaybe<ProjectManyRelationFilter>;
  tickets?: InputMaybe<TicketManyRelationFilter>;
};

export type UserWhereUniqueInput = {
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
};

export type ValidateUserPasswordResetTokenResult = {
  __typename?: 'ValidateUserPasswordResetTokenResult';
  code: PasswordResetRedemptionErrorCode;
  message: Scalars['String'];
};

export type ExtendedProjectIfoFragment = { __typename: 'Project', numberOfDomains?: number | null, startHosting?: any | null, runsCount?: number | null, id: string, website?: string | null, buildMinutes?: number | null };

export type BaseProjectIfoFragment = { __typename: 'Project', id: string, website?: string | null, buildMinutes?: number | null };

export type StatsByMonthFragment = { __typename?: 'StatsByMonthType', month?: number | null, year?: number | null, runs?: number | null, avgMinutes?: string | null, minutes?: string | null, minutesLeftThisMonth?: string | null };

export type SingleTicketResponseFragment = { __typename?: 'TicketResponse', id: string, createdAt?: any | null, message?: string | null, parent?: { __typename?: 'Ticket', id: string } | null, user?: { __typename?: 'User', id: string, displayName?: string | null } | null };

export type BaseUserIfoFragment = { __typename: 'User', id: string, displayName?: string | null };

export type CreateTicketMutationVariables = Exact<{
  data: TicketCreateInput;
}>;


export type CreateTicketMutation = { __typename?: 'Mutation', createTicket?: { __typename: 'Ticket', id: string, createdAt?: any | null } | null };

export type CreateTicketResponseMutationVariables = Exact<{
  data: TicketResponseCreateInput;
}>;


export type CreateTicketResponseMutation = { __typename?: 'Mutation', createTicketResponse?: { __typename?: 'TicketResponse', id: string, createdAt?: any | null, message?: string | null, parent?: { __typename?: 'Ticket', id: string } | null, user?: { __typename?: 'User', id: string, displayName?: string | null } | null } | null };

export type LoginMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
}>;


export type LoginMutation = { __typename?: 'Mutation', authenticateUserWithPassword?: { __typename: 'UserAuthenticationWithPasswordFailure', message: string } | { __typename: 'UserAuthenticationWithPasswordSuccess', sessionToken: string, item: { __typename: 'User', id: string, displayName?: string | null } } | null };

export type LogoutMutationVariables = Exact<{ [key: string]: never; }>;


export type LogoutMutation = { __typename?: 'Mutation', endSession: boolean };

export type RedeemPasswordResetTokenMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
  token: Scalars['String'];
}>;


export type RedeemPasswordResetTokenMutation = { __typename?: 'Mutation', redeemUserPasswordResetToken?: { __typename?: 'RedeemUserPasswordResetTokenResult', code: PasswordResetRedemptionErrorCode, message: string } | null };

export type SendPasswordResetLinkMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type SendPasswordResetLinkMutation = { __typename?: 'Mutation', sendUserPasswordResetLink: boolean };

export type UpdateUserMutationVariables = Exact<{
  id: Scalars['ID'];
  data: UserUpdateInput;
}>;


export type UpdateUserMutation = { __typename?: 'Mutation', updateUser?: { __typename: 'User', id: string, displayName?: string | null } | null };

export type GetLatestTicketQueryVariables = Exact<{ [key: string]: never; }>;


export type GetLatestTicketQuery = { __typename?: 'Query', authenticatedItem?: { __typename?: 'User', tickets?: Array<{ __typename: 'Ticket', id: string, createdAt?: any | null, status?: TicketStatusType | null, subject?: string | null, message?: string | null }> | null } | null };

export type GetTicketByIdQueryVariables = Exact<{
  ticketId: Scalars['ID'];
}>;


export type GetTicketByIdQuery = { __typename?: 'Query', authenticatedItem?: { __typename?: 'User', tickets?: Array<{ __typename: 'Ticket', id: string, createdAt?: any | null, status?: TicketStatusType | null, subject?: string | null, message?: string | null }> | null } | null };

export type GetTicketsQueryVariables = Exact<{
  take: Scalars['Int'];
  skip: Scalars['Int'];
}>;


export type GetTicketsQuery = { __typename?: 'Query', authenticatedItem?: { __typename?: 'User', ticketsCount?: number | null, tickets?: Array<{ __typename: 'Ticket', id: string, createdAt?: any | null, status?: TicketStatusType | null, subject?: string | null, message?: string | null }> | null } | null };

export type LatestStatsQueryVariables = Exact<{ [key: string]: never; }>;


export type LatestStatsQuery = { __typename?: 'Query', authenticatedItem?: { __typename: 'User', id: string, projects?: Array<{ __typename: 'Project', id: string, website?: string | null, buildMinutes?: number | null, statsByMonth?: Array<{ __typename?: 'StatsByMonthType', month?: number | null, year?: number | null, runs?: number | null, avgMinutes?: string | null, minutes?: string | null, minutesLeftThisMonth?: string | null } | null> | null }> | null } | null };

export type NotificationsQueryVariables = Exact<{ [key: string]: never; }>;


export type NotificationsQuery = { __typename?: 'Query', notifications?: Array<{ __typename?: 'Notification', id: string, title?: string | null, hideTitle?: boolean | null, closeable?: boolean | null, display?: NotificationDisplayType | null, severity?: NotificationSeverityType | null, start?: any | null, end?: any | null, message?: string | null }> | null };

export type ProjectsQueryVariables = Exact<{ [key: string]: never; }>;


export type ProjectsQuery = { __typename?: 'Query', authenticatedItem?: { __typename: 'User', id: string, projects?: Array<{ __typename: 'Project', numberOfDomains?: number | null, startHosting?: any | null, runsCount?: number | null, id: string, website?: string | null, buildMinutes?: number | null }> | null } | null };

export type QuerySingleStatQueryVariables = Exact<{
  skip?: InputMaybe<Scalars['Int']>;
}>;


export type QuerySingleStatQuery = { __typename?: 'Query', authenticatedItem?: { __typename: 'User', id: string, projects?: Array<{ __typename: 'Project', id: string, website?: string | null, buildMinutes?: number | null, statsByMonth?: Array<{ __typename?: 'StatsByMonthType', month?: number | null, year?: number | null, runs?: number | null, avgMinutes?: string | null, minutes?: string | null, minutesLeftThisMonth?: string | null } | null> | null }> | null } | null };

export type StatsLastYearQueryVariables = Exact<{ [key: string]: never; }>;


export type StatsLastYearQuery = { __typename?: 'Query', authenticatedItem?: { __typename: 'User', id: string, projects?: Array<{ __typename: 'Project', id: string, website?: string | null, buildMinutes?: number | null, statsByMonth?: Array<{ __typename?: 'StatsByMonthType', month?: number | null, year?: number | null, runs?: number | null, avgMinutes?: string | null, minutes?: string | null, minutesLeftThisMonth?: string | null } | null> | null }> | null } | null };

export type TestPageQueryVariables = Exact<{ [key: string]: never; }>;


export type TestPageQuery = { __typename?: 'Query', page?: { __typename?: 'Page', content?: { __typename?: 'Page_content_Document', document: any } | null } | null };

export type TicketResponsesQueryVariables = Exact<{
  parentId: Scalars['ID'];
}>;


export type TicketResponsesQuery = { __typename?: 'Query', ticketResponses?: Array<{ __typename?: 'TicketResponse', id: string, createdAt?: any | null, message?: string | null, parent?: { __typename?: 'Ticket', id: string } | null, user?: { __typename?: 'User', id: string, displayName?: string | null } | null }> | null };

export type UserQueryVariables = Exact<{ [key: string]: never; }>;


export type UserQuery = { __typename?: 'Query', authenticatedItem?: { __typename: 'User', id: string, displayName?: string | null } | null };

export type UserDataQueryVariables = Exact<{ [key: string]: never; }>;


export type UserDataQuery = { __typename?: 'Query', authenticatedItem?: { __typename: 'User', email?: string | null, ticketsCount?: number | null, id: string, displayName?: string | null } | null };

export const BaseProjectIfoFragmentDoc = gql`
    fragment BaseProjectIfo on Project {
  __typename
  id
  website
  buildMinutes
}
    `;
export const ExtendedProjectIfoFragmentDoc = gql`
    fragment ExtendedProjectIfo on Project {
  ...BaseProjectIfo
  numberOfDomains
  startHosting
  runsCount
}
    ${BaseProjectIfoFragmentDoc}`;
export const StatsByMonthFragmentDoc = gql`
    fragment StatsByMonth on StatsByMonthType {
  month
  year
  runs
  avgMinutes
  minutes
  minutesLeftThisMonth
}
    `;
export const SingleTicketResponseFragmentDoc = gql`
    fragment SingleTicketResponse on TicketResponse {
  id
  parent {
    id
  }
  createdAt
  user {
    id
    displayName
  }
  message
}
    `;
export const BaseUserIfoFragmentDoc = gql`
    fragment BaseUserIfo on User {
  __typename
  id
  displayName
}
    `;
export const CreateTicketDocument = gql`
    mutation CreateTicket($data: TicketCreateInput!) {
  createTicket(data: $data) {
    __typename
    id
    createdAt
  }
}
    `;
export type CreateTicketMutationFn = Apollo.MutationFunction<CreateTicketMutation, CreateTicketMutationVariables>;

/**
 * __useCreateTicketMutation__
 *
 * To run a mutation, you first call `useCreateTicketMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTicketMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTicketMutation, { data, loading, error }] = useCreateTicketMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateTicketMutation(baseOptions?: Apollo.MutationHookOptions<CreateTicketMutation, CreateTicketMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTicketMutation, CreateTicketMutationVariables>(CreateTicketDocument, options);
      }
export type CreateTicketMutationHookResult = ReturnType<typeof useCreateTicketMutation>;
export type CreateTicketMutationResult = Apollo.MutationResult<CreateTicketMutation>;
export type CreateTicketMutationOptions = Apollo.BaseMutationOptions<CreateTicketMutation, CreateTicketMutationVariables>;
export const CreateTicketResponseDocument = gql`
    mutation CreateTicketResponse($data: TicketResponseCreateInput!) {
  createTicketResponse(data: $data) {
    ...SingleTicketResponse
  }
}
    ${SingleTicketResponseFragmentDoc}`;
export type CreateTicketResponseMutationFn = Apollo.MutationFunction<CreateTicketResponseMutation, CreateTicketResponseMutationVariables>;

/**
 * __useCreateTicketResponseMutation__
 *
 * To run a mutation, you first call `useCreateTicketResponseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTicketResponseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTicketResponseMutation, { data, loading, error }] = useCreateTicketResponseMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateTicketResponseMutation(baseOptions?: Apollo.MutationHookOptions<CreateTicketResponseMutation, CreateTicketResponseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTicketResponseMutation, CreateTicketResponseMutationVariables>(CreateTicketResponseDocument, options);
      }
export type CreateTicketResponseMutationHookResult = ReturnType<typeof useCreateTicketResponseMutation>;
export type CreateTicketResponseMutationResult = Apollo.MutationResult<CreateTicketResponseMutation>;
export type CreateTicketResponseMutationOptions = Apollo.BaseMutationOptions<CreateTicketResponseMutation, CreateTicketResponseMutationVariables>;
export const LoginDocument = gql`
    mutation Login($email: String!, $password: String!) {
  authenticateUserWithPassword(email: $email, password: $password) {
    __typename
    ... on UserAuthenticationWithPasswordSuccess {
      sessionToken
      item {
        ...BaseUserIfo
      }
    }
    ... on UserAuthenticationWithPasswordFailure {
      message
    }
  }
}
    ${BaseUserIfoFragmentDoc}`;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const LogoutDocument = gql`
    mutation Logout {
  endSession
}
    `;
export type LogoutMutationFn = Apollo.MutationFunction<LogoutMutation, LogoutMutationVariables>;

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutMutation(baseOptions?: Apollo.MutationHookOptions<LogoutMutation, LogoutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LogoutMutation, LogoutMutationVariables>(LogoutDocument, options);
      }
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult = Apollo.MutationResult<LogoutMutation>;
export type LogoutMutationOptions = Apollo.BaseMutationOptions<LogoutMutation, LogoutMutationVariables>;
export const RedeemPasswordResetTokenDocument = gql`
    mutation RedeemPasswordResetToken($email: String!, $password: String!, $token: String!) {
  redeemUserPasswordResetToken(email: $email, password: $password, token: $token) {
    code
    message
  }
}
    `;
export type RedeemPasswordResetTokenMutationFn = Apollo.MutationFunction<RedeemPasswordResetTokenMutation, RedeemPasswordResetTokenMutationVariables>;

/**
 * __useRedeemPasswordResetTokenMutation__
 *
 * To run a mutation, you first call `useRedeemPasswordResetTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRedeemPasswordResetTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [redeemPasswordResetTokenMutation, { data, loading, error }] = useRedeemPasswordResetTokenMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *      token: // value for 'token'
 *   },
 * });
 */
export function useRedeemPasswordResetTokenMutation(baseOptions?: Apollo.MutationHookOptions<RedeemPasswordResetTokenMutation, RedeemPasswordResetTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RedeemPasswordResetTokenMutation, RedeemPasswordResetTokenMutationVariables>(RedeemPasswordResetTokenDocument, options);
      }
export type RedeemPasswordResetTokenMutationHookResult = ReturnType<typeof useRedeemPasswordResetTokenMutation>;
export type RedeemPasswordResetTokenMutationResult = Apollo.MutationResult<RedeemPasswordResetTokenMutation>;
export type RedeemPasswordResetTokenMutationOptions = Apollo.BaseMutationOptions<RedeemPasswordResetTokenMutation, RedeemPasswordResetTokenMutationVariables>;
export const SendPasswordResetLinkDocument = gql`
    mutation SendPasswordResetLink($email: String!) {
  sendUserPasswordResetLink(email: $email)
}
    `;
export type SendPasswordResetLinkMutationFn = Apollo.MutationFunction<SendPasswordResetLinkMutation, SendPasswordResetLinkMutationVariables>;

/**
 * __useSendPasswordResetLinkMutation__
 *
 * To run a mutation, you first call `useSendPasswordResetLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendPasswordResetLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendPasswordResetLinkMutation, { data, loading, error }] = useSendPasswordResetLinkMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useSendPasswordResetLinkMutation(baseOptions?: Apollo.MutationHookOptions<SendPasswordResetLinkMutation, SendPasswordResetLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendPasswordResetLinkMutation, SendPasswordResetLinkMutationVariables>(SendPasswordResetLinkDocument, options);
      }
export type SendPasswordResetLinkMutationHookResult = ReturnType<typeof useSendPasswordResetLinkMutation>;
export type SendPasswordResetLinkMutationResult = Apollo.MutationResult<SendPasswordResetLinkMutation>;
export type SendPasswordResetLinkMutationOptions = Apollo.BaseMutationOptions<SendPasswordResetLinkMutation, SendPasswordResetLinkMutationVariables>;
export const UpdateUserDocument = gql`
    mutation UpdateUser($id: ID!, $data: UserUpdateInput!) {
  updateUser(where: {id: $id}, data: $data) {
    ...BaseUserIfo
  }
}
    ${BaseUserIfoFragmentDoc}`;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const GetLatestTicketDocument = gql`
    query GetLatestTicket {
  authenticatedItem {
    ... on User {
      tickets(orderBy: {createdAt: desc}, take: 1) {
        __typename
        id
        createdAt
        status
        subject
        message
      }
    }
  }
}
    `;

/**
 * __useGetLatestTicketQuery__
 *
 * To run a query within a React component, call `useGetLatestTicketQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLatestTicketQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLatestTicketQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLatestTicketQuery(baseOptions?: Apollo.QueryHookOptions<GetLatestTicketQuery, GetLatestTicketQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLatestTicketQuery, GetLatestTicketQueryVariables>(GetLatestTicketDocument, options);
      }
export function useGetLatestTicketLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLatestTicketQuery, GetLatestTicketQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLatestTicketQuery, GetLatestTicketQueryVariables>(GetLatestTicketDocument, options);
        }
export type GetLatestTicketQueryHookResult = ReturnType<typeof useGetLatestTicketQuery>;
export type GetLatestTicketLazyQueryHookResult = ReturnType<typeof useGetLatestTicketLazyQuery>;
export type GetLatestTicketQueryResult = Apollo.QueryResult<GetLatestTicketQuery, GetLatestTicketQueryVariables>;
export const GetTicketByIdDocument = gql`
    query GetTicketById($ticketId: ID!) {
  authenticatedItem {
    ... on User {
      tickets(where: {id: {equals: $ticketId}}) {
        __typename
        id
        createdAt
        status
        subject
        message
      }
    }
  }
}
    `;

/**
 * __useGetTicketByIdQuery__
 *
 * To run a query within a React component, call `useGetTicketByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTicketByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTicketByIdQuery({
 *   variables: {
 *      ticketId: // value for 'ticketId'
 *   },
 * });
 */
export function useGetTicketByIdQuery(baseOptions: Apollo.QueryHookOptions<GetTicketByIdQuery, GetTicketByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTicketByIdQuery, GetTicketByIdQueryVariables>(GetTicketByIdDocument, options);
      }
export function useGetTicketByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTicketByIdQuery, GetTicketByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTicketByIdQuery, GetTicketByIdQueryVariables>(GetTicketByIdDocument, options);
        }
export type GetTicketByIdQueryHookResult = ReturnType<typeof useGetTicketByIdQuery>;
export type GetTicketByIdLazyQueryHookResult = ReturnType<typeof useGetTicketByIdLazyQuery>;
export type GetTicketByIdQueryResult = Apollo.QueryResult<GetTicketByIdQuery, GetTicketByIdQueryVariables>;
export const GetTicketsDocument = gql`
    query GetTickets($take: Int!, $skip: Int!) {
  authenticatedItem {
    ... on User {
      ticketsCount
      tickets(orderBy: {createdAt: desc}, take: $take, skip: $skip) {
        __typename
        id
        createdAt
        status
        subject
        message
      }
    }
  }
}
    `;

/**
 * __useGetTicketsQuery__
 *
 * To run a query within a React component, call `useGetTicketsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTicketsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTicketsQuery({
 *   variables: {
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useGetTicketsQuery(baseOptions: Apollo.QueryHookOptions<GetTicketsQuery, GetTicketsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTicketsQuery, GetTicketsQueryVariables>(GetTicketsDocument, options);
      }
export function useGetTicketsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTicketsQuery, GetTicketsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTicketsQuery, GetTicketsQueryVariables>(GetTicketsDocument, options);
        }
export type GetTicketsQueryHookResult = ReturnType<typeof useGetTicketsQuery>;
export type GetTicketsLazyQueryHookResult = ReturnType<typeof useGetTicketsLazyQuery>;
export type GetTicketsQueryResult = Apollo.QueryResult<GetTicketsQuery, GetTicketsQueryVariables>;
export const LatestStatsDocument = gql`
    query LatestStats {
  authenticatedItem {
    __typename
    ... on User {
      id
      projects {
        ...BaseProjectIfo
        statsByMonth(sort: desc, limit: 1) {
          ...StatsByMonth
        }
      }
    }
  }
}
    ${BaseProjectIfoFragmentDoc}
${StatsByMonthFragmentDoc}`;

/**
 * __useLatestStatsQuery__
 *
 * To run a query within a React component, call `useLatestStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLatestStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLatestStatsQuery({
 *   variables: {
 *   },
 * });
 */
export function useLatestStatsQuery(baseOptions?: Apollo.QueryHookOptions<LatestStatsQuery, LatestStatsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LatestStatsQuery, LatestStatsQueryVariables>(LatestStatsDocument, options);
      }
export function useLatestStatsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LatestStatsQuery, LatestStatsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LatestStatsQuery, LatestStatsQueryVariables>(LatestStatsDocument, options);
        }
export type LatestStatsQueryHookResult = ReturnType<typeof useLatestStatsQuery>;
export type LatestStatsLazyQueryHookResult = ReturnType<typeof useLatestStatsLazyQuery>;
export type LatestStatsQueryResult = Apollo.QueryResult<LatestStatsQuery, LatestStatsQueryVariables>;
export const NotificationsDocument = gql`
    query Notifications {
  notifications(where: {display: {notIn: hidden}}) {
    id
    title
    hideTitle
    closeable
    display
    severity
    start
    end
    message
  }
}
    `;

/**
 * __useNotificationsQuery__
 *
 * To run a query within a React component, call `useNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useNotificationsQuery(baseOptions?: Apollo.QueryHookOptions<NotificationsQuery, NotificationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NotificationsQuery, NotificationsQueryVariables>(NotificationsDocument, options);
      }
export function useNotificationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NotificationsQuery, NotificationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NotificationsQuery, NotificationsQueryVariables>(NotificationsDocument, options);
        }
export type NotificationsQueryHookResult = ReturnType<typeof useNotificationsQuery>;
export type NotificationsLazyQueryHookResult = ReturnType<typeof useNotificationsLazyQuery>;
export type NotificationsQueryResult = Apollo.QueryResult<NotificationsQuery, NotificationsQueryVariables>;
export const ProjectsDocument = gql`
    query Projects {
  authenticatedItem {
    __typename
    ... on User {
      id
      projects {
        ...ExtendedProjectIfo
      }
    }
  }
}
    ${ExtendedProjectIfoFragmentDoc}`;

/**
 * __useProjectsQuery__
 *
 * To run a query within a React component, call `useProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectsQuery({
 *   variables: {
 *   },
 * });
 */
export function useProjectsQuery(baseOptions?: Apollo.QueryHookOptions<ProjectsQuery, ProjectsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectsQuery, ProjectsQueryVariables>(ProjectsDocument, options);
      }
export function useProjectsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectsQuery, ProjectsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectsQuery, ProjectsQueryVariables>(ProjectsDocument, options);
        }
export type ProjectsQueryHookResult = ReturnType<typeof useProjectsQuery>;
export type ProjectsLazyQueryHookResult = ReturnType<typeof useProjectsLazyQuery>;
export type ProjectsQueryResult = Apollo.QueryResult<ProjectsQuery, ProjectsQueryVariables>;
export const QuerySingleStatDocument = gql`
    query QuerySingleStat($skip: Int) {
  authenticatedItem {
    __typename
    ... on User {
      id
      projects {
        ...BaseProjectIfo
        statsByMonth(sort: desc, limit: 1, skip: $skip) {
          ...StatsByMonth
        }
      }
    }
  }
}
    ${BaseProjectIfoFragmentDoc}
${StatsByMonthFragmentDoc}`;

/**
 * __useQuerySingleStatQuery__
 *
 * To run a query within a React component, call `useQuerySingleStatQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuerySingleStatQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuerySingleStatQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useQuerySingleStatQuery(baseOptions?: Apollo.QueryHookOptions<QuerySingleStatQuery, QuerySingleStatQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QuerySingleStatQuery, QuerySingleStatQueryVariables>(QuerySingleStatDocument, options);
      }
export function useQuerySingleStatLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QuerySingleStatQuery, QuerySingleStatQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QuerySingleStatQuery, QuerySingleStatQueryVariables>(QuerySingleStatDocument, options);
        }
export type QuerySingleStatQueryHookResult = ReturnType<typeof useQuerySingleStatQuery>;
export type QuerySingleStatLazyQueryHookResult = ReturnType<typeof useQuerySingleStatLazyQuery>;
export type QuerySingleStatQueryResult = Apollo.QueryResult<QuerySingleStatQuery, QuerySingleStatQueryVariables>;
export const StatsLastYearDocument = gql`
    query StatsLastYear {
  authenticatedItem {
    __typename
    ... on User {
      id
      projects {
        ...BaseProjectIfo
        statsByMonth(sort: desc, limit: 12) {
          ...StatsByMonth
        }
      }
    }
  }
}
    ${BaseProjectIfoFragmentDoc}
${StatsByMonthFragmentDoc}`;

/**
 * __useStatsLastYearQuery__
 *
 * To run a query within a React component, call `useStatsLastYearQuery` and pass it any options that fit your needs.
 * When your component renders, `useStatsLastYearQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStatsLastYearQuery({
 *   variables: {
 *   },
 * });
 */
export function useStatsLastYearQuery(baseOptions?: Apollo.QueryHookOptions<StatsLastYearQuery, StatsLastYearQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StatsLastYearQuery, StatsLastYearQueryVariables>(StatsLastYearDocument, options);
      }
export function useStatsLastYearLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StatsLastYearQuery, StatsLastYearQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StatsLastYearQuery, StatsLastYearQueryVariables>(StatsLastYearDocument, options);
        }
export type StatsLastYearQueryHookResult = ReturnType<typeof useStatsLastYearQuery>;
export type StatsLastYearLazyQueryHookResult = ReturnType<typeof useStatsLastYearLazyQuery>;
export type StatsLastYearQueryResult = Apollo.QueryResult<StatsLastYearQuery, StatsLastYearQueryVariables>;
export const TestPageDocument = gql`
    query TestPage {
  page(where: {slug: "/test/"}) {
    content {
      document(hydrateRelationships: true)
    }
  }
}
    `;

/**
 * __useTestPageQuery__
 *
 * To run a query within a React component, call `useTestPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useTestPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTestPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useTestPageQuery(baseOptions?: Apollo.QueryHookOptions<TestPageQuery, TestPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TestPageQuery, TestPageQueryVariables>(TestPageDocument, options);
      }
export function useTestPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TestPageQuery, TestPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TestPageQuery, TestPageQueryVariables>(TestPageDocument, options);
        }
export type TestPageQueryHookResult = ReturnType<typeof useTestPageQuery>;
export type TestPageLazyQueryHookResult = ReturnType<typeof useTestPageLazyQuery>;
export type TestPageQueryResult = Apollo.QueryResult<TestPageQuery, TestPageQueryVariables>;
export const TicketResponsesDocument = gql`
    query TicketResponses($parentId: ID!) {
  ticketResponses(
    where: {parent: {id: {equals: $parentId}}}
    orderBy: {createdAt: desc}
  ) {
    ...SingleTicketResponse
  }
}
    ${SingleTicketResponseFragmentDoc}`;

/**
 * __useTicketResponsesQuery__
 *
 * To run a query within a React component, call `useTicketResponsesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTicketResponsesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTicketResponsesQuery({
 *   variables: {
 *      parentId: // value for 'parentId'
 *   },
 * });
 */
export function useTicketResponsesQuery(baseOptions: Apollo.QueryHookOptions<TicketResponsesQuery, TicketResponsesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TicketResponsesQuery, TicketResponsesQueryVariables>(TicketResponsesDocument, options);
      }
export function useTicketResponsesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TicketResponsesQuery, TicketResponsesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TicketResponsesQuery, TicketResponsesQueryVariables>(TicketResponsesDocument, options);
        }
export type TicketResponsesQueryHookResult = ReturnType<typeof useTicketResponsesQuery>;
export type TicketResponsesLazyQueryHookResult = ReturnType<typeof useTicketResponsesLazyQuery>;
export type TicketResponsesQueryResult = Apollo.QueryResult<TicketResponsesQuery, TicketResponsesQueryVariables>;
export const UserDocument = gql`
    query User {
  authenticatedItem {
    __typename
    ... on User {
      ...BaseUserIfo
    }
  }
}
    ${BaseUserIfoFragmentDoc}`;

/**
 * __useUserQuery__
 *
 * To run a query within a React component, call `useUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserQuery(baseOptions?: Apollo.QueryHookOptions<UserQuery, UserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserQuery, UserQueryVariables>(UserDocument, options);
      }
export function useUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserQuery, UserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserQuery, UserQueryVariables>(UserDocument, options);
        }
export type UserQueryHookResult = ReturnType<typeof useUserQuery>;
export type UserLazyQueryHookResult = ReturnType<typeof useUserLazyQuery>;
export type UserQueryResult = Apollo.QueryResult<UserQuery, UserQueryVariables>;
export const UserDataDocument = gql`
    query UserData {
  authenticatedItem {
    __typename
    ... on User {
      ...BaseUserIfo
      email
      ticketsCount
    }
  }
}
    ${BaseUserIfoFragmentDoc}`;

/**
 * __useUserDataQuery__
 *
 * To run a query within a React component, call `useUserDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserDataQuery(baseOptions?: Apollo.QueryHookOptions<UserDataQuery, UserDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserDataQuery, UserDataQueryVariables>(UserDataDocument, options);
      }
export function useUserDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserDataQuery, UserDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserDataQuery, UserDataQueryVariables>(UserDataDocument, options);
        }
export type UserDataQueryHookResult = ReturnType<typeof useUserDataQuery>;
export type UserDataLazyQueryHookResult = ReturnType<typeof useUserDataLazyQuery>;
export type UserDataQueryResult = Apollo.QueryResult<UserDataQuery, UserDataQueryVariables>;