import Container from "@mui/material/Container";
import { styled } from "@mui/material/styles";

const AbsoluteCenter = styled(Container)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
}));

export default AbsoluteCenter;
